<template>
  <main id="main" class="grid place-content-center min-h-screen bg-[#111533]" tabindex="-1">
    <form @submit.prevent="submit">
      <label
        for="password"
        class="sr-only text-sm text-white"
      >
        Password
      </label>

      <div class="flex">
        <input
          id="password"
          name="password"
          type="password"
          class="form-input border-0 placeholder-gray-400"
          v-model="form.password"
          required
          spellcheck="false"
          placeholder="Password"
          autocomplete="off"
          data-1p-ignore
          autocapitalize="off"
        >

        <button
          type="submit"
          class="px-3 text-sm text-white bg-[#d45c17]"
        >
          Submit
        </button>
      </div>

      <div v-if="form.errors.password" class="mt-2 text-sm text-red-600">
        {{ form.errors.password }}
      </div>
    </form>
  </main>
</template>

<script setup lang="ts">
definePageMeta({
  layout: false,
});

useSeoMeta({
  robots: 'noindex, nofollow',
});

const router = useRouter();
const { public: { lock } } = useRuntimeConfig();

const lockPassword = useCookie('lock.password');
const lockRedirectUrl = useCookie('lock.redirect_url');

const form = reactive({
  password: '',
  errors: {
    password: '',
  },
});

const submit = () => {
  if (form.password !== lock.password) {
    form.errors.password = 'Invalid password.';

    return;
  }

  lockPassword.value = form.password;

  router.push({ path: lockRedirectUrl.value as string });
};
</script>
